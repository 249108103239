import { FormEvent, useMemo, useRef, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { loginWithMFA, validateRecaptchaHash } from 'services/authv2';
import { writeCrossStorageData } from 'services/crossStorage';
import { dispatchGtag } from 'utils/useGtag';

import { Input } from 'components/Input/Input';
import { ModalError, ModalInfo } from 'components/Modal/Modal';

const queryParams = new URLSearchParams(window.location.search);

const returnTo = queryParams.get('returnTo') || 'https://pismo.io';

const Login = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [showPasswordAndCodeFields, setShowPasswordAndCodeFields] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [errorCode, setErrorCode] = useState('');
  const statusEnv = process.env.REACT_APP_BASE_ENV;

  const siteKey = () => {
    if (statusEnv === 'prod') {
      return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY;
    }

    if (statusEnv === 'itau') {
      return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_ITAU;
    }

    if (statusEnv === 'india') {
      return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_INDIA;
    }

    if (statusEnv === 'citi-stag-usa') {
      return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_CITI;
    }

    if (statusEnv === 'usa') {
      return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_USA_PROD;
    }

    if (statusEnv === 'australia') {
      return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_AUS_PROD;
    }

    if (statusEnv === 'ireland') {
      return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_IRL_PROD;
    }

    return process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_EXT;
  };

  const { t } = useTranslation('pages');
  const recaptchaRef = useRef<ReCAPTCHA>();

  const buttonLabel = useMemo(() => {
    if (showPasswordAndCodeFields) {
      return loading ? t('Login.authenticating') : t('Login.authenticate');
    } else {
      return loading ? t('Login.requesting') : t('Login.request_access_code');
    }
  }, [loading, showPasswordAndCodeFields, t]);

  const registerLogin = async (data) => {
    try {
      // await clearCrossStorageData();
      await writeCrossStorageData(data);
      dispatchGtag({
        eventName: 'login',
        eventCategory: 'login_access',
        eventAction: 'click:button',
        eventLabel: 'success_access_login',
      });
      window.location.replace(returnTo);
    } catch {
      dispatchGtag({
        eventName: 'login',
        eventCategory: 'login_access',
        eventAction: 'click:button',
        eventLabel: 'error_access_login',
      });
      ModalError.fire({
        title: t('Login.warning'),
        text: t('Login.authentication_error_message_simplified'),
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
    }
  };

  const doRequestCode = async () => {
    setErrorEmail('');

    if (!email) {
      setErrorEmail(t('Login.validation_email'));
      return false;
    }

    setLoading(true);

    const recaptchaHash = await recaptchaRef.current.executeAsync();

    const { data, success } = await validateRecaptchaHash(
      email,
      password,
      recaptchaHash,
    );

    if (success && !data?.isMFAActive && Object.keys(data).length !== 0) {
      await registerLogin(data);
    }

    if ((!data?.isMFAActive && Object.keys(data).length === 0) || !success) {
      dispatchGtag({
        eventName: 'login',
        eventCategory: 'login_access',
        eventAction: 'click:button',
        eventLabel: 'error_access_login',
      });
      await ModalError.fire({
        title: t('Login.warning'),
        text: t('Login.authentication_error_message_simplified'),
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
    }

    setLoading(false);

    if (data?.isMFAActive) {
      await ModalInfo.fire({
        title: t('Login.access_code_request_title'),
        html: t('Login.access_code_request_message', {
          email: `<strong class="tw-text-accent-dark">${email}</strong>`,
        }),
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });

      setShowPasswordAndCodeFields(true);
    }
  };

  const doLogin = async () => {
    setErrorEmail('');
    setErrorPassword('');
    setErrorCode('');

    if (!email) {
      setErrorEmail(t('Login.validation_email'));
      return false;
    }

    if (!password) {
      setErrorPassword(t('Login.validation_password'));
      return false;
    }

    if (!code || !code.match(/\d{6}/)) {
      setErrorCode(t('Login.validation_access_code'));
      return false;
    }

    setLoading(true);

    const { success, data } = await loginWithMFA(email, password, code);

    setLoading(false);

    if (success) {
      await registerLogin(data);
    } else {
      dispatchGtag({
        eventName: 'login',
        eventCategory: 'login_access',
        eventAction: 'click:button',
        eventLabel: 'error_access_login',
      });
      await ModalError.fire({
        title: t('Login.warning'),
        text: t('Login.authentication_error_message_simplified'),
        showCancelButton: false,
        confirmButtonText: 'Ok',
      });
    }
  };

  const handleSubmit = async (ev: FormEvent) => {
    ev.preventDefault();

    dispatchGtag({
      eventName: 'login',
      eventCategory: 'login_access',
      eventAction: 'click:button',
      eventLabel: 'finish_access_login',
    });

    if (showPasswordAndCodeFields) {
      doLogin();
    } else {
      doRequestCode();
    }
  };

  const handleClickForgot = () => {
    dispatchGtag({
      eventName: 'login',
      eventCategory: 'login_forgot_password',
      eventAction: 'click:button',
      eventLabel: 'start_forgot_password_login',
    });
  };

  return (
    <form className="tw-p-6" onSubmit={(ev) => handleSubmit(ev)}>
      <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={siteKey()} />

      {!showPasswordAndCodeFields && (
        <>
          <div className="tw-mb-6">
            <Input
              label={t('Login.email')}
              starticon="mail"
              name="email"
              value={email}
              error={errorEmail !== ''}
              helpertext={errorEmail}
              disabled={loading}
              onChange={(ev) => setEmail(ev.target.value)}
            />
          </div>

          <div className="tw-mb-6">
            <Input
              type="password"
              label={t('Login.password')}
              starticon="lock"
              name="password"
              value={password}
              error={errorPassword !== ''}
              helpertext={errorPassword}
              disabled={loading}
              onChange={(ev) => setPassword(ev.target.value)}
            />
          </div>
        </>
      )}

      {showPasswordAndCodeFields && (
        <>
          <div className="tw-mb-6">
            <Input
              label={t('Login.access_code')}
              starticon="lock"
              name="code"
              value={code}
              maxlength={6}
              error={errorCode !== ''}
              helpertext={errorCode}
              disabled={loading}
              onChange={(ev) => setCode(ev.target.value)}
            />
          </div>
        </>
      )}

      <button
        type="submit"
        className="tw-btn tw-btn-primary tw-w-full tw-h-12 tw-flex tw-items-center"
        disabled={loading}
      >
        {buttonLabel}
      </button>

      <div className="tw-mt-4">
        <Link
          to="/passwordreset"
          className="tw-flex tw-items-center tw-text-gray-500 hover:tw-text-gray-700"
          onClick={handleClickForgot}
        >
          <span>{t('Login.forgot_password')}</span>
          <span className="tw-i-arrow-right tw-ml-2"></span>
        </Link>
      </div>
    </form>
  );
};

export { Login };
